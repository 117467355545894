const family = {
  parent: {
    "Bùi Văn Nghinh": {
      dob: 1902,
      deceased: 1951,
      gender: "male",
      isBloodline: true,
      detail: {
        short: "Nhà nho, nhà cách mạng. Giỗ ngày 3/11 AL",
        desc: "Theo lời kể của cụ Bùi Văn Chiêu là em trai của cụ Bùi Văn Nghinh, các con trai cùng những người cao tuổi trong xã. Cụ Bùi Văn Nghinh là người văn, võ song toàn. Sống nhân đức, giúp dân biết chữ lại bốc thuốc chữa bệnh cho dân nghèo tại quê hương. Cụ vừa là thầy đồ, vừa chữa bệnh cứu người, biết võ nghệ lại biết xem phong thủy, làm thầy cúng giúp dân - do đời trước để lại gia đình Cụ có ngôi điện thờ Thánh. Sau này, khi cách mạng về chính Cụ là người giải Điện để tham gia kháng chiến chống Pháp. Cụ mất do đại bác của giặc Pháp bắn trên đường đi vào Bậc Đụn, sau khi bị thương được dân quân, du kích và bộ đội địa phương khiêng lên núi Hang Đình đến đêm thì Cụ đi! Mặc dù cụ mất sớm để lại nhiều tiếc nuối cho đời nhưng tinh hoa của cụ thì còn mãi truyền đến các thế hệ hiện tại và tương lai."
      }
    },
    "Nguyễn Thị Khi": {
      dob: 1901,
      deceased: 1986,
      gender: "female",
      detail: {
        short: "Giỗ ngày 26/8 AL"
      }
    }
  },
  children: [
    {
      parent: {
        "Bùi Thị Nhuy": {
          dob: 1925,
          deceased: 2014,
          gender: "female",
          isBloodline: true
        },
        "Nguyễn Văn Khởi": {
          dob: "?",
          deceased: "?",
          gender: "male"
        }
      },
      children: [
        {
          parent: {
            "Nguyễn Thị Kim": {
              dob: 1955,
              gender: "female"
            },
            "Vũ Phúc Thiêm": {
              dob: 1950,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Vũ Thị Thanh": {
                  dob: 1976,
                  gender: "female"
                },
                "Nguyễn Văn Quỳnh": {
                  dob: 1973,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Thị Khánh Ly": {
                      dob: 2000,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Vũ Trường": {
                      dob: 2009,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Vũ Văn Cảnh": {
                  dob: 1980,
                  gender: "male"
                },
                "Phạm Thị Hằng": {
                  dob: 1993,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Vũ Nhật Minh": {
                      dob: 2013,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Vũ Bảo Nam": {
                      dob: 2017,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Vũ Thị Ảnh": {
                  dob: 1982,
                  gender: "female"
                },
                "Lương Hữu Ngà": {
                  dob: 1984,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Lương Thị Hoa": {
                      dob: 2007,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Lương Thị Ngân": {
                      dob: 2011,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Lương Việt Anh": {
                      dob: 2014,
                      gender: "male"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Nguyễn Thị Băng": {
              dob: 1960,
              gender: "female"
            },
            "Mai Văn Mừng": {
              dob: 1961,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Nguyễn Trung Đạt": {
                  dob: 1996,
                  gender: "male"
                }
              }
            }
          ]
        },
        {
          parent: {
            "Nguyễn Thị Thảo": {
              dob: 1963,
              gender: "female"
            },
            "Nguyễn Văn Phảy": {
              dob: 1960,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Nguyễn Thị Thu Hường": {
                  dob: 1984,
                  gender: "female"
                },
                "Bùi Mạnh Thực": {
                  dob: 1980,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Tùng Anh": {
                      dob: 2008,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Trí Dũng": {
                      dob: 2013,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Nguyễn Phi Long": {
                  dob: 1986,
                  gender: "male"
                },
                "Nguyễn Thị Quỳnh Anh": {
                  dob:1990,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Hà Linh": {
                      dob: 2018,
                      gender: "female"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Nguyễn Thị Bảo": {
              dob: 1967,
              gender: "female"
            },
            "Bùi Ngọc Huệ": {
              dob: 1961,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Bùi Tiến Chỉnh": {
                  dob: 1985,
                  gender: "male"
                },
                "Nguyễn Thị Hiền": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Nguyễn Hải Anh": {
                      dob: 2012,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Quỳnh Nga": {
                      dob: 2019,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Huyên": {
                  dob: 1988,
                  gender: "female"
                },
                "Nguyễn Trung Kiên": {
                  dob: 1980,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Thị Kiều Linh": {
                      dob: 2015,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Quyên": {
                  dob: 1993,
                  gender: "female"
                },
                "Mai Văn Mỹ": {
                  dob: 1993,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Mai Minh Khang": {
                      dob: 2019,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Mai Đức Anh": {
                      dob: 2020,
                      gender: "male"
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      parent: {
        "Bùi Thị Duỵ": {
          dob: "?",
          gender: "female",
          isBloodline: true
        },
        "Bạch Văn Tính": {
          dob: 1924,
          gender: "male",
          deceased: 1995
        }
      },
      children: [
        {
          parent: {
            "Bạch Văn Chữ": {
              dob: 1949,
              gender: "male",
              deceased: "?"
            },
          }
        },
        {
          parent: {
            "Bạch Thị Nghĩa": {
              dob: 1951,
              gender: "female"
            },
            "Bùi Tiến Bộ": {
              dob: "?",
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Bùi Văn Tuy": {
                  dob: "?",
                  gender: "male"
                },
                "Lê Thuý Hằng": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Lê Kiên": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Đức Anh": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Đức Thụ": {
                  dob: "?",
                  gender: "male"
                },
                "Nguyễn Thị Thuỷ": {
                  dob: "?",
                  gender: "female",
                  divorced: true
                },
                "Nguyễn Thị Kiều": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Thị Tuyết Mai": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Thị Mỹ Anh": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Tiến Đạt": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Nguyễn Tường Vi": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Nguyễn Cát Tường": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Đức Trí": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Ngọc Hân": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
              ]
            },
            {
              parent: {
                "Bùi Thị Thắm": {
                  dob: "?",
                  gender: "female"
                },
                "Bạch Văn Hạnh": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Bạch Diễm Quỳnh": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bạch Thị Thanh Thảo": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bạch Công Anh": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Duyên": {
                  dob: "?",
                  gender: "female"
                },
                "Nguyễn Văn Thà": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Tuấn Hà": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Đức Trọng": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
              ]
            },
            {
              parent: {
                "Bùi Thị Thanh Thuỷ": {
                  dob: 1984,
                  gender: "female",
                  detail: {
                    short: "Giáo viên mầm non",
                    desc: "Sinh ngày: 25/5/1984. Nghề nghiệp Giáo Viên. Đơn vị công tác: Mầm non Yên Hoà, Cầu Giấy "
                  }
                },
                "Bùi Văn Toàn": {
                  dob: "?",
                  gender: "male"
                },
              },
              children: [
                {
                  parent: {
                    "Bùi Long Hải": {
                      dob: 2007,
                      gender: "male",
                      detail: {
                        short: "Học sinh",
                        desc: "Sinh ngày: 25/07/2007. Hiện đang là Học sinh (2021)."
                      }
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Hương Trà": {
                      dob: 2012,
                      gender: "female",
                      detail: {
                        short: "Học sinh",
                        desc: "Sinh ngày: 15/12/2012. Hiện đang là Học sinh (2021)."
                      }
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Văn Quân": {
                  dob: "?",
                  gender: "male"
                },
                "Nguyễn Thị Thu Hồng": {
                  dob: "?",
                  gender: "female",
                  divorced: true
                },
                "Đoàn Ngọc Lan": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Gia Huy": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Bảo An": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Bảo Ngọc": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bạch Thị Sáng": {
              dob: "?",
              gender: "female"
            },
            "Nguyễn Mạnh Tiến": {
              dob: "?",
              deceased: 2013,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Nguyễn Thị Luyến": {
                  dob: 1978,
                  gender: "female"
                },
                "Đinh Trung Hiếu": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Đinh Phúc Tuấn": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Đinh Nhật Minh": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Nguyễn Anh Tuyên": {
                  dob: 1979,
                  gender: "male"
                },
                "Đinh Thị Cam": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Trung Đức": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Thành Đạt": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Nguyễn Anh Quang": {
                  dob: 1981,
                  gender: "male"
                },
                "Phan Thị Thu": {
                  dob: 1982,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Phúc An Khang": {
                      dob: 2011,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Phúc Gia Thành": {
                      dob: 2015,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Nguyễn Quang Long": {
                  dob: 1983,
                  gender: "male"
                },
                "Nguyễn Thị Tâm": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Kiều Trang": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Gia Phú": {
                      dob: 2015,
                      gender: "male"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bạch Văn Suốt": {
              dob: 1958,
              deceased: 1961,
              gender: "male"
            }
          }
        },
        {
          parent: {
            "Bạch Thị Trưa": {
              dob: 1959,
              gender: "female"
            },
            "Hà Văn Lựu": {
              dob: "?",
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Hà Vũ Thắng": {
                  dob: 1988,
                  gender: "male"
                },
                "Nguyễn Thị Thanh": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Hà Thành Nam": {
                      dob: 2012,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Hà Đại Phú": {
                      dob: 2017,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Hà Ái My": {
                      dob: 2021,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Hà Thị Mỹ Lệ": {
                  dob: 1982,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Lê Hà Trường Thiên": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Huỳnh Hà Gia Phúc": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Hà Thị Xuyến": {
                  dob: 1983,
                  gender: "female"
                },
                "Trần Văn Quân": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Trần Tuấn Anh": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Trần Huy Hoàng": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Trần Thị Hồng Nhung": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bạch Thị Chiều": {
              dob: 1961,
              gender: "female"
            },
            "Đinh Văn Hiển": {
              dob: "?",
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Đinh Thị Hoàn": {
                  dob: 1983,
                  gender: "female"
                }
              }
            },
            {
              parent: {
                "Đinh Đức Thiện": {
                  dob: 1985,
                  gender: "male"
                },
                "Nguyễn Thị Trang": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Đinh Đức Chiến": {
                      dob: 2013,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Đinh Thảo Nguyên": {
                      dob: 2016,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Đinh Bảo Yến": {
                  dob: 1989,
                  gender: "female"
                },
                "Trần Đăng Huy": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Trần Ngọc Hân": {
                      dob: 2012,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Trần Đăng Huy Hoàng": {
                      dob: 2013,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Đinh Tố Uyên": {
                  dob: 1992,
                  gender: 'female'
                },
                "Vũ Văn Giang": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Vũ Thế Phong": {
                      dob: 2008,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Vũ Minh Ngọc": {
                      dob: 2017,
                      gender: "female"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bạch Thị Hằng": {
              dob: 1964,
              gender: "female"
            },
            "Bùi Văn Hoan": {
              dob: "?",
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Bùi Thị Hạnh": {
                  dob: 1984,
                  gender: 'female'
                },
                "Đinh Công Thuỵ": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Đinh Thị Hương Giang": {
                      dob: 2001,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Đinh Thị Hương Diễm": {
                      dob: 2004,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Đinh Công Thuỷ": {
                      dob: 2012,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Mai Huy": {
                  dob: 1985,
                  gender: "male"
                },
                "Nguyễn Thị Tỉnh": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Dân Hiệp": {
                      dob: 2015,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Mai Hương": {
                      dob: 2018,
                      gender: "male"
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      parent: {
        "Bùi Văn Rật": {
          dob: "?",
          deceased: 1992,
          gender: "male",
          detail: {
            short: "Giỗ ngày 29/7 AL."
          },
          isBloodline: true
        },
        "Đinh Thị Dung": {
          dob: 1930,
          gender: "female"
        }
      },
      children: [
        {
          parent: {
            "Bùi Duy Nhan": {
              dob: 1956,
              gender: "male",
              isBloodline: true
            },
            "Phạm Thị Quế": {
              dob: "?",
              gender: "female",
              divorced: true
            },
            "Lê Thị Thanh": {
              dob: "?",
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Khánh Toàn": {
                  dob: 1982,
                  gender: "male",
                  isBloodline: true
                },
                "Lê Thị Thuý": {
                  dob: 1984,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Lê Khánh Vân": {
                      dob: 2012,
                      gender: "female",
                      isBloodline: true
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Gia Khánh": {
                      dob: 2015,
                      gender: "male",
                      isBloodline: true
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Quyên": {
                  dob: 1984,
                  gender: "female",
                  isBloodline: true
                },
                "Chu Đức Tuân": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Chu Đức Việt Anh": {
                      dob: 2010,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Chu Đức Hoàng Anh": {
                      dob: 2011,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Duy Nhàn": {
                  dob: 1992,
                  gender: "male"
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Duy Khang": {
              dob: 1958,
              gender: "male",
              isBloodline: true
            },
            "Vũ Thị Vượng": {
              dob: 1960,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Phượng Liên": {
                  dob: 1991,
                  gender: "female",
                  isBloodline: true
                }
              }
            },
            {
              parent: {
                "Bùi Yến Ly": {
                  dob: 1995,
                  gender: "female",
                  isBloodline: true
                },
                "Thái Anh Tú": {
                  dob: 1993,
                  gender: "male",
                  detail: {
                    short: "Kỹ sư CNTT",
                    desc: "Nguyên quán Hà Tĩnh"
                  }
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Văn Trường": {
              dob: 1961,
              gender: "male",
              isBloodline: true
            },
            "Hoàng Lệ Chi": {
              dob: 1966,
              gender: "female",
              divorced: true
            }
          },
          children: [
            {
              parent: {
                "Bùi Hoàng Mỹ Linh": {
                  dob: 1991,
                  gender: "female",
                  isBloodline: true
                },
                "Carlos Armando Nunez Jr.": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Leon Hoang Nunez": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Luna Bui Nunez": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Hoàng Mỹ Hạnh": {
                  dob: 1993,
                  gender: "female",
                  isBloodline: true
                }
              }
            },
          ]
        },
        {
          parent: {
            "Bùi Thị Chung": {
              dob: 1964,
              gender: "female",
              isBloodline: true
            },
            "Nguyễn Tiến Khởi": {
              dob: 1957,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Nguyễn Văn Quyết": {
                  dob: 1986,
                  gender: "male"
                },
                "Nguyễn Thị Minh Thu": {
                  dob: "?",
                  gender: "female",
                  divorced: true
                },
                "Nguyễn Thị Thuỳ": {
                  dob: "?",
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Khánh Ngọc": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Khánh Linh": {
                      dob: "?",
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Nguyễn Thị Huệ": {
                  dob: 1992,
                  gender: "female"
                },
                "Cao Đức Hiệp": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Cao Minh Hoàng": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bùi Thị Thuỳ": {
              dob: 1967,
              gender: "female",
              isBloodline: true
            },
            "Nguyễn Văn Ba": {
              dob: 1967,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Nguyễn Thị Mai Hương": {
                  dob: 1990,
                  gender: "female"
                },
                "Lê Tuấn Dũng": {
                  dob: 1989,
                  gender: "male"
                }
              }
            },
            {
              parent: {
                "Nguyễn Thị Thu Trà": {
                  dob: 1994,
                  gender: "female"
                }
              }
            },
            {
              parent: {
                "Nguyễn Thị Hồng Ngọc": {
                  dob: 2001,
                  gender: "female"
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Văn Côn": {
              dob: 1970,
              gender: "male",
              isBloodline: true,
              detail: {
                short: "Đại Tá, Cục Quân Lực, Bộ tổng tham mưu",
                desc: [
                  "Sinh 01/6/1970",
                  "3/1988-6/1989: Nhập ngũ e86/BTL Hoá Học",
                  "7/1989-6/1992: Học viên Trường SQ CHKT Thông tin.",
                  "7/1992-6/1995: Trung uý, Trung đội trưởng, tiểu đoàn 26, Trường SQ CHKT Thông tin",
                  "7/1995-2/1998: Thượng uý, Trợ lý tác chiến Trường SQ CHKT Thông tin",
                  "3/1998-01/2001: Đại uý, Trợ lý Thông tin, Đảo Nam Yết, Đảo Song Tử Tây, lữ 146, Vùng 4 Hải Quân",
                  "02/2001-8/2008: Thiếu tá, Trung tá, trợ lý quân lực, Bộ CHQS tỉnh Hà Tây.",
                  "9/2008-5/2011: Trợ lý quân lực, Bộ Tư lệnh, BTL Thủ đô Hà Nội",
                  "6/2011- 4/2013: Phó CHT’ Ban CHQS quận Ba Đình, BTL Thủ đô Hà Nội",
                  "5/2013-6/2017: Thượng tá, Trợ lý Động viên, Cục Quân lực/BTTM",
                  "7/2017: Đại tá",
                  "3/2021: Phó Trưởng phòng Động viên/ Cục Quân lực/BTTM"
                ]
              }
            },
            "Nguyễn Thanh Huyền": {
              dob: 1975,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Minh Đức": {
                  dob: 2009,
                  gender: "male",
                  isBloodline: true
                }
              }
            },
            {
              parent: {
                "Bùi Minh Ngọc": {
                  dob: 2017,
                  gender: "female",
                  isBloodline: true
                }
              }
            }
          ]
        }
      ]
    },
    {
      parent: {
        "Bùi Văn Cự": {
          dob: "?",
          deceased: 1951,
          isBloodline: true,
          gender: "male",
          detail: {
            short: "Giỗ ngày 2/11 AL"
          }
        }
      }
    },
    {
      parent: {
        "Bùi Văn Ngự": {
          dob: 1932,
          gender: "male",
          isBloodline: true,
          detail: {
            short: "Bộ đội pháo binh, viên chức viện Vệ Sinh Dịch Tễ",
            desc: "Nhập ngũ ngày 14/9/1965. Chuyển công tác sang viện Vệ Sinh Dịch Tễ ngày 30/5/1974. Nghỉ hưu tháng 12/1996."
          }
        },
        "Nguyễn Thị Sim": {
          dob: 1930,
          gender: "female",
          detail: {
            short: "Làm nông tại thôn Trù, Tuy Lai, Mỹ Đức, Hà Tây."
          }
        }
      },
      children: [
        {
          parent: {
            "Bùi Văn Sử": {
              dob: 1957,
              gender: "male",
              isBloodline: true,
              detail: {
                short: "Công an nhân dân",
                desc: "Sinh ra và lớn lên ở Tuy Lai, Mỹ Đức, Hà Tây. Học tại học viên Cảnh Sát Nhân Dân, công tác tại CA Hà Sơn Bình, Hà Tây và Hà Nội.",
                phrases: {
                  "Gia đình và học hành": "Học phổ thông tại Tuy Lai, Mỹ Đức.",
                  "Sự nghiệp và công tác": "Công tác tại Phòng Cảnh Sát Giao Thông Công An tỉnh Hà Sơn Bình, tỉnh Hà Tây, thành phố Hà Nội.",
                  "Hưu trí và gia đình": "Nghỉ hưu với chức vụ Đội Phó, cấp bậc Thượng Tá. Hiện tại đang ở số 32, đường 18M, tổ 16 khu giãn dân Mộ Lao, Hà Đông, Hà Nội."
                }
              }
            },
            "Tạ Thị Thuý": {
              dob: 1966,
              gender: "female",
              detail: {
                short: "Giáo viên tiểu học",
                desc: "Sinh ra và lớn lên ở Liên Bạt, Ứng Hoà, Hà Tây. Học tại trường Trung cấp Sư Phạm Xuân Mai; sau học Đại học Sư Phạm. Dạy tại trường Xuân Mai, An Khánh, Kiến Hưng, Trần Phú. Nghỉ hưu năm 2021.",
                phrases: {
                  "Gia đình và học hành": "Sinh ra và lớn lên ở xóm Bặt Bún, xã Liên Bạt, huyện Ứng Hoà, Hà Tây. Học tại trường Trung cấp Sư Phạm Xuân Mai, sau đó học Đại học Sư Phạm.",
                  "Sự nghiệp và công tác": "Dạy học tại trường Tiểu học Xuân Mai A 11 năm, sau đó dạy tại An Khánh, Hoài Đức; Kiến Hưng và Trần Phú, Hà Đông.",
                  "Hưu trí và gia đình": "Nghỉ hưu tại trường tiểu học Trần Phú, Hà Đông. Hiện tại đang ở số 32, đường 18M, tổ 16 khu giãn dân Mộ Lao, Hà Đông, Hà Nội."
                }
              }
            }
          },
          children: [
            {
              parent: {
                "Bùi Trung Hiếu": {
                  dob: 1988,
                  gender: "male",
                  isBloodline: true,
                  detail: {
                    short: "Kỹ sư phần mềm"
                  }
                },
                "Đoàn Thanh Hà": {
                  dob: 1988,
                  gender: "female",
                  detail: {
                    short: "Tư vấn truyền thông, thiết kế."
                  }
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Khánh Hân": {
                      dob: 2015,
                      gender: "female",
                      isBloodline: true
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Nhật Kiên": {
                      dob: 2020,
                      gender: "male",
                      isBloodline: true
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Hải Yến": {
                  dob: 1992,
                  gender: "female",
                  isBloodline: true
                },
                "Tạ Trí Linh": {
                  dob: 1989,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Tạ Trí An" : {
                      dob: 2020,
                      gender: "male"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bùi Thị Sam": {
              dob: 1960,
              gender: "female",
              isBloodline: true
            },
            "Nguyễn Văn Uân": {
              dob: 1957,
              gender: "male",
              deceased: 1990
            }
          },
          children: [
            {
              parent: {
                "Nguyễn Thị Hà": {
                  dob: 1983,
                  gender: "female"
                },
                "Nguyễn Văn Hải": {
                  dob: 1980,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Minh Hiếu": {
                      dob: 2007,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Thị Ngọc": {
                      dob: 2011,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Nguyễn Duy Hùng": {
                  dob: 1992,
                  gender: "male"
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Văn Đăng": {
              dob: 1963,
              gender: "male",
              isBloodline: true
            },
            "Nguyễn Thị Thắm": {
              dob: 1966,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Thị Vân Anh": {
                  dob: 1990,
                  gender: "female",
                  isBloodline: true
                },
                "Nguyễn Bá Toàn": {
                  dob: 1985,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Bá Đức Minh": {
                      dob: 2017,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Bá Đức Khôi": {
                      dob: 2020,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Minh Hồng": {
                  dob: 1992,
                  gender: "female",
                  isBloodline: true
                }
              }
            },
            {
              parent: {
                "Bùi Long Hải": {
                  dob: 1998,
                  gender: "male",
                  isBloodline: true
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Văn Năng": {
              dob: 1970,
              gender: "male",
              isBloodline: true,
              detail: {
                short: "Công tác tại Viện Vệ Sinh Dịch Tễ Trung Ương",
                desc: "Sinh ngày 15/01/1971. Nơi sinh: Thôn Trù, Tuy Lai, Mỹ Đức, HN. Nghề nghiệp: Cử nhân sinh học, đang làm việc tại Viện vệ sinh dịch tễ Trung ương. Địa chỉ nhà: Số 103 A11 Ngõ 90 Phố Bùi Ngọc Dương, HBT, HN."
              }
            },
            "Kiều Thị Thuỷ": {
              dob: 1974,
              gender: "female",
              detail: {
                short: "Công tác tại Bệnh viện Bạch Mai",
                desc: "Sinh ngày 20/11/1974. Nơi sinh: Thị trấn Lộc Bình, Tỉnh Lạng Sơn. Nghề nghiệp: Cử nhân sinh học, đang làm việc tại bệnh viện Bạch Mai. Địa chỉ nhà: Số 103 A11 Ngõ 90 Phố Bùi Ngọc Dương, HBT, HN."
              }
            }
          },
          children: [
            {
              parent: {
                "Bùi Quang Anh": {
                  dob: 1998,
                  gender: "male",
                  detail: {
                    short: "Sinh viên đại học FPT (2021).",
                    desc: "Sinh ngày 24/04/1999. Nơi sinh: Hà nội. Hiện đang là Sinh viên đại học FPT (2021). Địa chỉ nhà: Số 103 A11 Ngõ 90 Phố Bùi Ngọc Dương, HBT, HN."
                  },
                  isBloodline: true
                }
              }
            },
            {
              parent: {
                "Bùi Nhật Minh": {
                  dob: 2002,
                  gender: "male",
                  isBloodline: true,
                  detail: {
                    short: "Sinh viên đại học mỹ thuật công nghiệp (2021).",
                    desc: "Sinh ngày 12/08/2002. Nơi sinh: Hà nội. Sinh viên đại học mỹ thuật công nghiệp (2021). Địa chỉ nhà: Số 103 A11 Ngõ 90 Phố Bùi Ngọc Dương, HBT, HN."
                  }
                }
              }
            }
          ]
        }
      ]
    },
    {
      parent: {
        "Bùi Văn Cử": {
          dob: 1940,
          deceased: 2014,
          isBloodline: true,
          gender: "male",
          detail: {
            short: "Giỗ ngày 30/2 AL"
          }
        },
        "Bạch Thị Múc": {
          dob: 1940,
          gender: "female"
        }
      },
      children: [
        {
          parent: {
            "Bùi Văn Tú": {
              dob: 1961,
              gender: "male",
              isBloodline: true
            },
            "Nguyễn Thị Hậu": {
              dob: 1968,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Thị Hương Giang": {
                  dob: 1984,
                  gender: "female",
                  isBloodline: true
                },
                "Nguyễn Đình Tài": {
                  dob: "?",
                  gender: "male",
                  divorced: true
                }
              },
              children: [
                {
                  parent: {
                    "Bui Ngọc Bảo An": {
                      dob: 2017,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Thu Hà": {
                  dob: 1985,
                  gender: "female",
                  isBloodline: true
                },
                "Nguyễn Đắc Đông": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Đắc Phi": {
                      dob: 2007,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Đắc Toàn": {
                      dob: 2013,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Thị Linh Nhi": {
                      dob: 2019,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Minh Hoạt": {
                  dob: "?",
                  gender: "female",
                  isBloodline: true
                },
                "Nguyễn Đắc Tùng": {
                  dob: "?",
                  gender: "male",
                  divorced: true
                },
                "Bùi Hoàng Khải": {
                  dob: "?",
                  gender: "male"
                },
              },
              children: [
                {
                  parent: {
                    "Nguyễn Hoàng Gia Phát": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Nguyễn Hoàng Đại Quang": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Thuỳ Linh": {
                  dob: "?",
                  gender: "female",
                  isBloodline: true
                },
                "Trần Xuân Dũng": {
                  dob: "?",
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Trần An Phúc": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Anh Đức": {
                  đob: "?",
                  gender: "male",
                  isBloodline: true
                },
                "Vũ Thị Lệ": {
                  dob: "?",
                  gender: "female"
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Thị Hài": {
              dob: 1964,
              gender: "female",
              isBloodline: true
            },
            "Đỗ Khắc Toan": {
              dob: 1963,
              gender: "male"
            }
          },
          children: [
            {
              parent: {
                "Đỗ Khắc Tiến": {
                  dob: 1984,
                  gender: "male"
                },
                "Tạ Thị Thu Hiền": {
                  dob: 1989,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Đỗ Tạ Bảo Anh": {
                      dob: 2014,
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Đỗ Tạ Châu Anh": {
                      dob: 2017,
                      gender: "female"
                    }
                  }
                }  
              ]
            },
            {
              parent: {
                "Đỗ Thị Hải Yến": {
                  dob: 1989,
                  gender: "female"
                },
                "Bùi Phúc Minh": {
                  dob: 1988,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Phúc An": {
                      dob: 2014,
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Phúc Thịnh": {
                      dob: 2017,
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Đỗ Thị Tuyết Mai": {
                  dob: 1991,
                  gender: "female"
                },
                "Bùi Đức Duy": {
                  dob: 1989,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Bùi Đỗ Thanh Nguyên": {
                      dob: "?",
                      gender: "male"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Đỗ Gia Hân": {
                      dob: "2014",
                      gender: "female"
                    }
                  }
                },
                {
                  parent: {
                    "Bùi Đỗ Thành Trung": {
                      dob: "2017",
                      gender: "male"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Đỗ Tiến Hiệp": {
                  dob: 1993,
                  gender: "male"
                },
                "Đỗ Thị Hương": {
                  dob: 1998,
                  gender: "female"
                }
              },
              children: [
                {
                  parent: {
                    "Đỗ Thảo Vy": {
                      dob: 2017,
                      gender: "female"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          parent: {
            "Bùi Văn Động": {
              dob: 1967,
              gender: "male",
              isBloodline: true
            },
            "Lê Thị Phương": {
              dob: 1972,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Thị Mai Châm": {
                  dob: 1992,
                  gender: "female",
                  isBloodline: true
                },
                "Nguyễn Quang Hữu": {
                  dob: 1991,
                  gender: "male"
                }
              },
              children: [
                {
                  parent: {
                    "Nguyễn Hoàng Gia Hân": {
                      dob: 2018,
                      gender: "female"
                    }
                  }
                }
              ]
            },
            {
              parent: {
                "Bùi Thị Phương Oanh": {
                  dob: 1995,
                  gender: "female",
                  isBloodline: true
                },
                "Nguyễn Duy Thức": {
                  dob: 1994,
                  gender: "male"
                }
              }
            },
            {
              parent: {
                "Bùi Thị Phương Thảo": {
                  dob: 2002,
                  gender: "female",
                  isBloodline: true
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Trọng Cơ": {
              dob: 1973,
              gender: "male",
              isBloodline: true
            },
            "Phùng Thị Loan": {
              dob: 1976,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Mỹ Phượng": {
                  dob: 1996,
                  gender: "female",
                  isBloodline: true
                }
              }
            },
            {
              parent: {
                "Bùi Minh Hoàng": {
                  dob: 2000,
                  gender: "male",
                  isBloodline: true
                }
              }
            }
          ]
        },
        {
          parent: {
            "Bùi Trọng Tài": {
              dob: 1975,
              gender: "male",
              isBloodline: true
            },
            "Hoàng Thị Hoa": {
              dob: 1986,
              gender: "female"
            }
          },
          children: [
            {
              parent: {
                "Bùi Hoàng Long": {
                  dob: 2008,
                  gender: "male",
                  isBloodline: true
                }
              }
            },
            {
              parent: {
                "Bùi Khánh Ly": {
                  dob: 2010,
                  gender: "female",
                  isBloodline: true
                }
              }
            }
          ]
        }
      ]
    },
    {
      parent: {
        "Bùi Văn Dư": {
          dob: "?",
          deceased: 1968,
          gender: "male",
          isBloodline: true
        }
      }
    }
  ]
}

export default family