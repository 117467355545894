import React from 'react'
import Person from './Person/Person'
import {ExpandMore, ExpandLess, KeyboardArrowRight, FavoriteBorder} from '@material-ui/icons'

class FamilyNode extends React.Component {
  constructor({parent, children, expanded, showBibliography}){
    super()
    this.state = {expanded: expanded}
  }

  render() {
    const {parent, children, showBibliography} = this.props
    const isExpandable = children !== undefined ? Array.isArray(children) === true && children.length > 0 ? true: false : false
    const onClick = isExpandable? this.state.expanded? () => { this.setState({expanded: false}) } :
    () => { this.setState({expanded: true}) } : null;
    return (
      <div className="family">
        <div className="parent">
          {isExpandable?
            this.state.expanded? <ExpandMore onClick={onClick} />: 
            <ExpandLess onClick={onClick} />
          : <KeyboardArrowRight color="disabled" />}
          {Object.keys(parent).map((name, idx) => {
            const data = parent[name]
            const personClick = () => {
              if(showBibliography !== undefined){
                showBibliography({...data, name})
              }
            }
            return idx < Object.keys(parent).length-1 ? 
              <><Person.NodeView {...data} name={name} key={idx} onClick={personClick}/><FavoriteBorder /></>
              : <Person.NodeView {...data} name={name} key={idx} onClick={personClick}/>
          })}
        </div>
        {this.state.expanded === true && isExpandable === true?
        <div className="children">
          {children.map((child, idx) => {
            return <FamilyNode {...child} key={idx} expanded={false} showBibliography={showBibliography}/>
          })}
        </div>
        :null}
      </div>
    )
  }
}

export default FamilyNode