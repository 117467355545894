import React from 'react'
import classNames from 'classnames'

class NodeView extends React.Component {
  constructor({gender, isBloodline, name, onClick}){
    super()
  }

  render(){
    const {gender, isBloodline, name, deceased, divorced, onClick} = this.props
    return <div className={classNames(gender, isBloodline === true?'blood-line':null, "person-node-view", deceased ? "deceased":null, divorced ? "divorced" : null)} onClick={onClick}>
      {name}
    </div>
  }
}

export default NodeView