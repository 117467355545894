import React from 'react'
import Collapsable from '../Collapsable'

class FullView extends React.Component {
  constructor(props){
    super()
  }
  render(){
    let {detail, name, dob, deceased} = this.props
    detail = detail === undefined ? {} : detail
    return <div className="person-full-view" >
      <div className="h1">{name}</div>
      <div className="h5">
        {deceased === undefined ? "Sinh năm "+dob: "("+dob+"-"+deceased+")"}.
        &nbsp;{detail.short !== undefined? detail.short:null}
      </div>
      {detail.desc? <>
        <div className="h3">Tóm tắt tiểu sử</div>
        <div className="h5">{typeof detail.desc === 'string'? detail.desc : Array.isArray(detail.desc) ? 
          <ul>{detail.desc.map((itm, k) => {
            return <li key={k}>{itm}</li>
          })}</ul>:null}
        </div>
      </>:null}
      {detail.phrases?
        Object.keys(detail.phrases).map((title, idx) => {
          return <Collapsable key={idx} title={title} content={detail.phrases[title]} />
        })
      : null}
    </div>
  }
}

export default FullView