import React from 'react'
import {ExpandMore, ExpandLess} from '@material-ui/icons'
import classNames from 'classnames'

class Collapsable extends React.Component {
  constructor({className, title, content}){
    super()
    this.state = {collapsed: false}
  }
  render() {
    const {title, content} = this.props
    const onClick = () => {
      this.setState({collapsed: !this.state.collapsed})
    }
    return <div className={classNames(this.props.className, "collapsable")}>
      <div className="collapsable-title h3">
        <span>{title}</span>
        {this.state.collapsed === true? <ExpandMore onClick={onClick} /> : <ExpandLess onClick={onClick} />}
      </div>
      {this.state.collapsed === true?<div className="collapsable-content h5">{content}</div>:null}
    </div>
  }
}

export default Collapsable