import './style/index.scss';
import RootNode from './data';
import { UIHelperProvider } from './context/UIHelperContext';
import IndexPage from './page/IndexPage';
import DefaultAppBar from './component/DefaultAppBar';

function App() {

  return (
    <UIHelperProvider>
      <DefaultAppBar menuHandler={() => {}} title="Phả hệ Bùi Văn Nghinh" />
      <div style={{paddingLeft: "15px"}}>
        <h4>
          <p>"Đường lâu ngày không đi sẽ mọc đầy cỏ dại</p>
          <p>Người lâu ngày không gặp sẽ trở thành người dưng"</p>
        </h4>
        <div>Phả hệ Bùi Văn Nghinh ra đời với mục đích trước tiên là bày tỏ lòng biết ơn công lao sinh thành và dưỡng dục của Cụ và các thế hệ đi trước, nhưng đồng thời cũng giúp con cháu của Cụ hiểu thêm về cội nguồn của bản thân, gia đình và dòng tộc. Đây là động lực để các thế hệ hiện tại và tương lai kết nối để phát huy những truyền thống quý báu của dòng họ.</div>
        <div>Phả hệ chỉ mang tính kế thừa và phát triển nếu liên tục được bổ sung thông tin cụ thể của từng thành viên. Mọi nhu cầu sửa đổi, bổ sung xin vui lòng liên hệ nhóm Zalo của gia đình.</div>
      </div>
      <IndexPage rootNode={RootNode} />
    </UIHelperProvider>
  );
}

export default App;
