import { createContext, useContext, useState } from "react";
import { Snackbar, Backdrop, CircularProgress, Drawer, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UIHelperContext = createContext()

export function useUIHelper(){
  return useContext(UIHelperContext)
}

export function UIHelperProvider ({children}){
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [backdropState, setBackdropState] = useState(false)
  const [drawer, setDrawer] = useState({})
  const classes = useStyles();

  const value = {
    setErrorMessage,
    setSuccessMessage,
    setBackdropState,
    setDrawer: function(children){
      setDrawer({opened: true, children})
    }
  }
  return (
    <UIHelperContext.Provider value={value}>
      {children}
      <Snackbar open={errorMessage !== ''} autoHideDuration={6000} onClose={() => setErrorMessage('')} anchorOrigin={{ vertical:'top', horizontal:'center'}}>
        <Alert onClose={()=>setErrorMessage('')} severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar open={successMessage !== ''} autoHideDuration={6000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical:'top', horizontal:'center'}}>
        <Alert onClose={()=>setSuccessMessage('')} severity="success">{successMessage}</Alert>
      </Snackbar>
      <Backdrop key={backdropState} className={classes.backdrop} open={backdropState} onClick={() => {
        setBackdropState(false)
      }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Drawer open={drawer.opened !== true ? false:true } onClose={() => {
        setDrawer({})
      }} anchor="right">
        <div className="container">
          <div style={{paddingBottom: "16px"}}>
           <Button variant="contained" color="secondary" onClick={() => {setDrawer({})}}>Đóng</Button> 
          </div>
          {drawer.children !== undefined ? drawer.children: null}
        </div>
      </Drawer>
    </UIHelperContext.Provider>
  )
}