import React from 'react'
import FamilyNode from '../component/FamilyNode'
import Person from '../component/Person/Person'
import { useUIHelper } from '../context/UIHelperContext'

const IndexPage = function({rootNode}) {
  const {setDrawer} = useUIHelper()
  return <div className="pt16">
  <FamilyNode {...rootNode} expanded={false} showBibliography={(data) => {
    setDrawer(<Person.FullView {...data}/>)
  }} />
  </div>
}
export default IndexPage